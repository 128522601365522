import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import config from "../lib/config.js";
import moment from "moment";
import Web3 from "web3";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import USDTABI from "../ABI/USDTABI.json";
import GoldinosABI from "../ABI/GoldinosABI.json";
import ClockLoader from "react-spinners/ClockLoader";
import { toastAlert } from "../helper/toastAlert.js";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import {
  getSignatureRejoin,
  getPlanDetails,
  getSignature,
  getWithdrawHis,
  getRoyaltiData,
  getUserRankInfo,
  getGenealogyTree,
  get_user_income_hist,
  getSettingsData,
  getRankHistory
} from "../Actions/apiAction.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayFormattedAddress } from "../helper/formatAddr.js";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Dashboard(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [planData, setPlanData] = useState([]);
  const [latestPlan, setLatestPlan] = useState([]);
  const [withdrawHis, setWithdrawHis] = useState([]);
  const [ddepHis, setDepHis] = useState([]);
  const [dirHis, setDDirHis] = useState([]);
  const [royaltyAchi, setRoyalAchi] = useState(0);
  const [royaltyVol, setRoyalVol] = useState(0);
  const [currRank, setCurrRank] = useState("");

  const [treeData, setTreeData] = useState();
  const [ROIHist, setROIHist] = useState([]);
  const [ROIuplinerHist, setROIuplinerHist] = useState([]);
  const [earndReward, setEardReward] = useState(0);
  const [withFee, setWithFee] = useState(0);
  const [withdrawStop, setWithdrawStop] = useState(false);
  const [rankHis, setRankHis] = useState([]);
  const [rankHis1, setRankHis1] = useState([]);
  const [historyTree, setHistoryTree] = useState([]);

  function formatNumberWithFourDecimalPlaces(number) {
    const fixedNumber = (Math.floor(number * 10000) / 10000).toFixed(4);
    return fixedNumber;
  }

  console.log(userDetails, "userDetails");
  useEffect(() => {
    getdetails();
    getPlanData();
    getWithdrawHistory();
    getROIDats();
    getRankAchi();
    getGenealogy();
    getROIHistory();
    getSetting();
    getRanksIncome();
  }, [walletConnection.address]);

  useEffect(() => {
    userStatusCheck();
  }, [walletConnection.address]);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("wagmi.store")).state.data.account == null
    ) {
      window.location.href = "/";
    }
  }, []);

  const getRanksIncome = async () => {
    try {
      if (
        walletConnection &&
        walletConnection.address &&
        walletConnection.userdetails
      ) {
        const payload = {
          userID: walletConnection.userdetails?.userID,
          address: walletConnection.address
        };
        const data = await getRankHistory(payload);
        console.log(data, "dataRankkkkkkkkkkkkk");
        data?.result.map((ele, i) => {
          ele.SSNo = i + 1;
          ele.GoldValPer =
            (ele?.amount * (percentcal(ele?.goldPer, 0) / ele?.gold?.length)) /
            100;
          ele.EmraldValPer =
            (ele?.amount *
              (percentcal(ele?.emraldPer, 0) / ele?.emrald?.length)) /
            100;
          ele.DimondValPer =
            (ele?.amount *
              (percentcal(ele?.DimondPer, 0) / ele?.Dimond?.length)) /
            100;
          ele.BlueDimondValPer =
            (ele?.amount *
              (percentcal(ele?.BlueDimondPer, 0) / ele?.BlueDimond?.length)) /
            100;
          ele.CrownDimondValPer =
            (ele?.amount *
              (percentcal(ele?.CrownDimondPer, 0) / ele?.CrownDimond?.length)) /
            100;
          ele.AmbasitorValPer =
            (ele?.amount *
              (percentcal(ele?.AmbasitorPer, 0) / ele?.Ambasitor?.length)) /
            100;
        });
        console.log(data?.result, "wsdcadasfasfc");
        // {(hist?.amount *
        //   (percentcal(hist?.goldPer, 0) / hist.gold?.length)) /
        //   100}
        setRankHis(data?.result);
        setRankHis1(data?.result?.[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getROIHistory = async () => {
    try {
      if (
        walletConnection &&
        walletConnection.address &&
        walletConnection.userdetails
      ) {
        const payload = {
          userID: walletConnection.userdetails?.userID
        };
        const { result, result1, status } = await get_user_income_hist(payload);
        console.log(result, result1, status, "result, statusrgehetdhrth4t");
        result?.map((ele, i) => {
          ele.SSNo = i + 1;
        });
        result1?.map((ele, i) => {
          ele.SSNo = i + 1;
        });
        if (status) {
          setROIHist(result);
          setROIuplinerHist(result1);
        }
      }
    } catch (err) {
      console.log(err, "errrrr");
    }
  };

  const userStatusCheck = async () => {
    try {
      if (walletConnection.address) {
        var web3 = new Web3(walletConnection.web3);
        var ContractGol = new web3.eth.Contract(
          GoldinosABI,
          config.GolfiContract
        );

        let userdetails = await ContractGol.methods
          .users(walletConnection.address)
          .call();
        console.log(userdetails, "userdetailswedceasdf");
        if (!userdetails.isExist) {
          navigate("/join-now");
        }
      }
    } catch (err) {
      console.log(err, "userStatusCheck");
    }
  };

  async function getdetails() {
    try {
      if (
        walletConnection &&
        walletConnection.address &&
        walletConnection.userdetails
      ) {
        if (walletConnection.userdetails && !walletConnection?.userdetails) {
          window.location.href = "/join-now";
        } else {
          console.log(
            walletConnection.userdetails,
            "walletConnection.userdetails"
          );
          setUserDetails(walletConnection.userdetails);
        }
      }
    } catch (err) {
      console.log(err, "getdetails");
    }
  }

  const getGenealogy = async () => {
    try {
      if (walletConnection?.userdetails?.userID) {
        const payload = {
          userID: walletConnection.userdetails?.userID
        };
        const data = await getGenealogyTree(payload);
        console.log(data?.data?.result, "Gebeaokdlsdlwweewwefewf");
        setTreeData(data?.data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getWithdrawHistory = async () => {
    try {
      if (walletConnection?.userdetails?.userID) {
        const payload = {
          userID: walletConnection.userdetails?.userID
        };
        const data = await getWithdrawHis(payload);
        console.log(data, "Withsss");
        await data?.data?.result?.map((ele, i) => {
          ele.SSNo = i + 1;
        });
        setWithdrawHis(data?.data?.result);
        setEardReward(data?.data?.dataEarAmt?.[0]?.totalAmt);
      }
    } catch (err) {
      console.log(err, "WithdrawHis");
    }
  };

  const getPlanData = async () => {
    try {
      if (walletConnection?.userdetails?.userID) {
        const payload = {
          userID: walletConnection?.userdetails?.userID
        };
        const plans = await getPlanDetails(payload);
        console.log(plans?.data?.result3, "plans");
        plans?.data?.result3.map((ele, i) => {
          ele.SSNo = i + 1;
        });
        plans?.data?.result2.map((ele, i) => {
          ele.SSNo = i + 1;
        });
        setPlanData(plans?.data?.Ppdata);
        setLatestPlan(plans?.data?.result1);
        setDepHis(plans?.data?.result2);
        setDDirHis(plans?.data?.result3);
      }
    } catch (err) {
      console.log(err, "PlnDataGet");
    }
  };

  const getROIDats = async () => {
    try {
      if (walletConnection?.userdetails?.userID) {
        const payload = {
          userID: walletConnection?.userdetails?.userID
        };
        const data = await getRoyaltiData(payload);
        console.log(data, "Royaltiiii");
        setRoyalAchi(data?.data?.Achiver);
        setRoyalVol(data?.data?.RFund);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRankAchi = async () => {
    try {
      if (walletConnection?.userdetails?.userID) {
        const payload = {
          userID: walletConnection?.userdetails?.userID
        };
        const data = await getUserRankInfo(payload);
        console.log(data, "Rabnkkkkkkk");
        setCurrRank(data?.data?.Rank);
        // setRoyalAchi(data?.data?.result.length);
        // setRoyalVol(data?.data?.totalAmt[0]?.totalAmount);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(userDetails, "userDetails");

  const getSetting = async () => {
    try {
      const data = await getSettingsData();
      console.log(data, "Settings");
      setWithFee(data?.result?.withDrawFeesAmount);
      setWithdrawStop(data?.result?.withdrawStatus);
    } catch (rtt) {
      console.log(rtt);
    }
  };

  async function copyToClipboard() {
    if (walletConnection.address) {
      copy(config.frontend + "/join-now/" + walletConnection.address);
      toastAlert("success", "Copied Successfully");
    } else {
      toastAlert("error", "Please connect your wallet");
    }
  }

  const handleRejoin = async (pId, planAmount) => {
    try {
      setLoader(true);
      if (walletConnection.address) {
        var web3 = new Web3(walletConnection.web3);

        let bnbBal = await web3.eth.getBalance(walletConnection.address);
        bnbBal = bnbBal / config.decimalVal;
        console.log(bnbBal, "bnbBal");

        const ContractToken = new web3.eth.Contract(
          USDTABI,
          config.USDTContract
        );
        let tokenBal = await ContractToken.methods
          .balanceOf(walletConnection.address)
          .call();
        tokenBal = tokenBal / 1e18;
        console.log(tokenBal, "tokenBal");

        const ContractGolfi = new web3.eth.Contract(
          GoldinosABI,
          config.GolfiContract
        );
        console.log(ContractGolfi, "ContractGolfi");

        let isBlock = await ContractGolfi.methods
          .isBlocked(walletConnection.address)
          .call();
        console.log(isBlock, "isBlock");

        if (isBlock) {
          toastAlert("error", "Your Blocked by admin");
          setLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }

        const isRegistr = await ContractGolfi.methods.isRegister().call();
        console.log(isRegistr, "isRegistrRejoin");
        if (!isRegistr) {
          toastAlert("error", "Currently registration is stoped by admin");
          setLoader(false);
          return false;
        }

        console.log(planAmount, "firstPlan");
        if (Number(planAmount) > Number(tokenBal)) {
          toastAlert("error", "Your USDT balance is low");
          setLoader(false);
          return false;
        }

        let estimateGas = await ContractToken.methods
          .approve(config.GolfiContract, (planAmount * 1e18).toString())
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));
        let gasPricebusd = await web3.eth.getGasPrice();
        console.log(estimateGas * gasPricebusd, "estimateGas");
        let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
        console.log(estimateGasFee, bnbBal, estimateGasFee > bnbBal, "edwfced");
        if (estimateGasFee > bnbBal) {
          toastAlert("error", "Your BNB balance is low");
          setLoader(false);
          return false;
        }
        console.log(Date.now(), "sfdsfcd");

        const Time = Date.now();
        await ContractToken.methods
          .approve(config.GolfiContract, (planAmount * 1e18).toString())
          .send({ from: walletConnection.address })
          .then(async (result) => {
            if (result && result.status && result.transactionHash) {
              const payload = {
                address: walletConnection.address,
                planId: pId
              };
              const getSign = await getSignatureRejoin(payload);
              console.log(getSign, "getSSign");
              const signData = getSign?.data?.result?.signature;
              const TimeData = getSign?.data?.result?.validSig;
              console.log(pId.toString(), signData, TimeData, "DataRejoin");
              await ContractGolfi.methods
                .rejoin(pId.toString(), signData, TimeData.toString())
                .send({ from: walletConnection.address })
                .then((res) => {
                  if (res && res.status && res.transactionHash) {
                    toastAlert("success", "Re-Joined Successfully");
                    // setLoader(false);
                    setTimeout(() => {
                      window.location.reload();
                    }, 4000);
                  }
                })
                .catch((err) => {
                  console.log(err, "ErrorJoinPl");
                  setLoader(false);
                });
            }
          })
          .catch((err) => {
            console.log(err, "ErrorAppro");
            setLoader(false);
          });
      } else {
        toastAlert("error", "Please connect wallet");
        setLoader(false);
        navigate("/");
      }
    } catch (err) {
      console.log(err, "SubmitErr");
    }
  };

  function generateRandomSixDigitNumber() {
    const min = 100000; // Smallest 6-digit number (100000)
    const max = 999999; // Largest 6-digit number (999999)

    // Generate a random number between min and max (inclusive)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNumber;
  }

  const handleWithdraw = async (amount) => {
    try {
      setLoader(true);
      if (walletConnection.address) {
        if (amount == 0) {
          toastAlert("error", "Amount must be greater than zero");
          setLoader(false);
          return false;
        }
        var web3 = new Web3(walletConnection.web3);

        let bnbBal = await web3.eth.getBalance(walletConnection.address);
        bnbBal = bnbBal / config.decimalVal;
        console.log(bnbBal, "bnbBal");

        const ContractGolfi = new web3.eth.Contract(
          GoldinosABI,
          config.GolfiContract
        );
        console.log(ContractGolfi, "ContractGolfi");

        let isBlock = await ContractGolfi.methods
          .isBlocked(walletConnection.address)
          .call();
        console.log(isBlock, "isBlockWith");

        if (isBlock) {
          toastAlert("error", "Your Blocked by admin");
          setLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }

        const randomSixDigitNumber = await generateRandomSixDigitNumber();
        console.log(randomSixDigitNumber, "randomSixDigitNumber");
        const payload = {
          address: walletConnection.address,
          amount: amount,
          randNumber: randomSixDigitNumber
        };
        console.log(payload, "payload");
        const getSign = await getSignature(payload);
        console.log(getSign, "getSSignWithdraw");
        const signData = getSign?.data?.result?.signature;
        const Amount = getSign?.data?.result?.amount;
        console.log(signData, "DataSignatureCheckkkkkk", Amount);

        let estimateGas = await ContractGolfi.methods
          .withdraw(
            (Amount * 1e18).toString(),
            walletConnection.address,
            randomSixDigitNumber,
            signData,
            0
          )
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));
        let gasPricebusd = await web3.eth.getGasPrice();
        console.log(estimateGas * gasPricebusd, "estimateGas");
        let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
        console.log(estimateGasFee, bnbBal, estimateGasFee > bnbBal, "edwfced");
        if (estimateGasFee > bnbBal) {
          toastAlert("error", "Your BNB balance is low");
          setLoader(false);
          return false;
        }

        await ContractGolfi.methods
          .withdraw(
            (Amount * 1e18).toString(),
            walletConnection.address,
            randomSixDigitNumber,
            signData,
            0
          )
          .send({ from: walletConnection.address })
          .then((res) => {
            if (res && res.status && res.transactionHash) {
              toastAlert("success", "Withdraw Successfully");
              setTimeout(() => {
                window.location.reload();
              }, 4000);
              // setLoader(false);
            }
          })
          .catch((err) => {
            console.log(err, "ErrorJoinPl");
            toastAlert("error", "Withdraw Rejected");
            setTimeout(() => {
              window.location.reload();
            }, 4000);
            // setLoader(false);
          });
      } else {
        toastAlert("error", "Please connect wallet");
        setLoader(false);
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancelWithdraw = async (
    amount,
    signData,
    randomSixDigitNumber
  ) => {
    try {
      setLoader(true);
      if (walletConnection.address) {
        var web3 = new Web3(walletConnection.web3);

        let bnbBal = await web3.eth.getBalance(walletConnection.address);
        bnbBal = bnbBal / config.decimalVal;
        console.log(bnbBal, "bnbBal");

        const ContractGolfi = new web3.eth.Contract(
          GoldinosABI,
          config.GolfiContract
        );
        console.log(ContractGolfi, "ContractGolfi");

        let isBlock = await ContractGolfi.methods
          .isBlocked(walletConnection.address)
          .call();
        console.log(isBlock, "isBlockWith");

        if (isBlock) {
          toastAlert("error", "Your Blocked by admin");
          setLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }

        console.log(signData, "DataRejoinWith");

        let estimateGas = await ContractGolfi.methods
          .cancelWithdraw(
            (amount * 1e18).toString(),
            walletConnection.address,
            randomSixDigitNumber,
            signData,
            0
          )
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));
        let gasPricebusd = await web3.eth.getGasPrice();
        console.log(estimateGas * gasPricebusd, "estimateGas");
        let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
        console.log(estimateGasFee, bnbBal, estimateGasFee > bnbBal, "edwfced");
        if (estimateGasFee > bnbBal) {
          toastAlert("error", "Your BNB balance is low");
          setLoader(false);
          return false;
        }

        await ContractGolfi.methods
          .cancelWithdraw(
            (amount * 1e18).toString(),
            walletConnection.address,
            randomSixDigitNumber,
            signData,
            0
          )
          .send({ from: walletConnection.address })
          .then((res) => {
            if (res && res.status && res.transactionHash) {
              toastAlert("success", "Cancel Withdraw Successfully");
              // setLoader(false);
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            }
          })
          .catch((err) => {
            console.log(err, "ErrorJoinPl");
            // setLoader(false);
            toastAlert("error", "Rejected cancel withdraw request");
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          });
      } else {
        toastAlert("error", "Please connect wallet");
      }
    } catch (err) {
      console.log(err);
    }
  };

  function addToAmount(referrals) {
    let totalAmount = 0;
    console.log(referrals, "referrals");
    // Loop through the referrals and sum their "amount" values
    for (const referral of referrals) {
      totalAmount += referral?.amount;
    }
    console.log(totalAmount, "totalAmount");
    return totalAmount;
  }

  const handleTreeChange = async (userID) => {
    try {
      console.log(userID, "handleTreeChange");
      const payload = {
        userID: userID
      };
      const data = await getGenealogyTree(payload);
      console.log(data?.data?.result, "Gebeaokdlsdl");
      setTreeData(data?.data?.result);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(historyTree, "historyTreeerfwefr");
  const onHistoryChange = async () => {
    historyTree.pop();
    console.log(historyTree, "historyTree");
    if (historyTree.length == 0) {
      console.log(treeData.userid, "treeData.useridqwde");
      handleTreeChange(walletConnection?.userdetails?.userID);
    } else {
      handleTreeChange(historyTree[historyTree.length - 1]);
    }
  };

  function Items({ currentItems }) {
    return (
      <div class="accordion-body">
        <div class="table-responsive mt-3">
          <table class="table mb-0">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                {/* <th scope="col">ID</th> */}

                <th scope="col">Date/Time</th>
                {/* <th scope="col">Package</th> */}
                <th scope="col">Withdraw Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((ele) => {
                  return (
                    <tr>
                      <td>{ele.SSNo}</td>
                      {/* <td>ID {ele.userID}</td> */}

                      <td>{moment(ele.createdAt).format("lll")}</td>
                      {/* <td>
                    {latestPlan ? latestPlan : 0} USDT
                  </td> */}
                      <td>{ele.amount.toFixed(4)} USDT </td>
                      <td className="">
                        {ele?.Status == 1
                          ? "Rejected"
                          : ele?.Status == 2
                          ? "Success"
                          : "Cancel"}
                      </td>
                      <td className="">
                        {ele?.Status == 1 ? (
                          <button
                            class="join_btn"
                            disabled={withdrawStop}
                            onClick={() =>
                              handleCancelWithdraw(
                                ele.amount,
                                ele.sig,
                                ele.nonce
                              )
                            }
                          >
                            Cancel
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = withdrawHis.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(withdrawHis.length / itemsPerPage);
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % withdrawHis.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <div className="row">
          <Items currentItems={currentItems} />
        </div>
        {withdrawHis.length > 9 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </>
    );
  }

  function ItemsROI({ currentItems }) {
    return (
      <div class="accordion-body">
        <div class="table-responsive mt-3">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                {/* <th scope="col">ID</th> */}
                <th scope="col">Passive Rewards</th>
                <th scope="col">User Amount</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((hist, i) => {
                  return (
                    <tr>
                      <td>{hist?.SSNo}</td>
                      {/* <td>{hist?.userID}</td> */}
                      <td>{hist.RoiAmount}</td>
                      <td>{hist.UserAmount}</td>
                      <td>{moment(hist.createdAt).format("lll")}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function PaginatedItemsROI({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = ROIHist.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(ROIHist.length / itemsPerPage);
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ROIHist.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <div className="row">
          <ItemsROI currentItems={currentItems} />
        </div>
        {ROIHist.length > 5 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </>
    );
  }

  function ItemsROIUpli({ currentItems }) {
    return (
      <div class="accordion-body">
        <div class="table-responsive mt-3">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S.No</th>

                <th scope="col">Downline ID</th>
                <th scope="col">Passive Rewards</th>
                <th scope="col">User Amount</th>
                <th scope="col">Level</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((hist) => {
                  return (
                    <tr>
                      <td>{hist?.SSNo}</td>

                      <td>{hist?.childId}</td>
                      <td>{hist.roiAmount}</td>
                      <td>{hist.userAmount}</td>
                      <td>{hist?.level + 1}</td>
                      <td>{moment(hist.createdAt).format("lll")}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function PaginatedItemsROIUpli({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = ROIuplinerHist.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(ROIuplinerHist.length / itemsPerPage);
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ROIuplinerHist.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <div className="row">
          <ItemsROIUpli currentItems={currentItems} />
        </div>
        {ROIuplinerHist.length > 5 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </>
    );
  }

  function ItemsDiret({ currentItems }) {
    return (
      <div class="accordion-body">
        <div class="table-responsive mt-3">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Date</th>
                {/* <th scope="col">ID</th> */}
                <th scope="col">Downline ID</th>
                <th scope="col">Amount</th>
                <th scope="col">Package</th>
                {/* <th scope="col">Status</th> */}
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((hist) => {
                  return (
                    <tr>
                      {console.log(currentItems, "currentItems")}
                      <td>{hist?.SSNo}</td>
                      <td>{moment(hist.createdAt).format("lll")}</td>
                      {/* <td>{hist?.parentId}</td> */}
                      <td>{hist?.userID}</td>
                      <td>{hist?.direcetIncome} USDT</td>
                      <td>{hist?.planAmount} USDT</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function PaginatedItemsItemsDiret({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = dirHis.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(dirHis.length / itemsPerPage);
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % dirHis.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <div className="row">
          <ItemsDiret currentItems={currentItems} />
        </div>
        {dirHis.length > 5 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </>
    );
  }

  function ItemsPacHis({ currentItems }) {
    return (
      <div class="accordion-body">
        <div class="table-responsive mt-3">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                {/* <th scope="col">ID</th> */}
                <th scope="col">Date/Time</th>
                <th scope="col">Package</th>
                <th scope="col">Amount</th>
                <th scope="col" className="text-end">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((hist) => {
                  return (
                    <tr>
                      <td>{hist?.SSNo}</td>
                      {/* <td>ID {hist?.userID}</td> */}
                      <td>{moment(hist.createdAt).format("lll")}</td>
                      <td>{hist.planAmount} USDT</td>
                      <td>{hist.roiAmount.toFixed(4)} USDT </td>
                      <td className="text-end">
                        {hist.status == 1 ? "Active" : "Inactive"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function PaginatedItemsItemsPacHis({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = ddepHis.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(ddepHis.length / itemsPerPage);
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % ddepHis.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <div className="row">
          <ItemsPacHis currentItems={currentItems} />
        </div>
        {ddepHis.length > 9 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </>
    );
  }

  function PaginatedItemsRank({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = rankHis.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(rankHis.length / itemsPerPage);
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % rankHis.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <>
        <div className="row">
          <ItemsRank currentItems={currentItems} />
        </div>
        {rankHis.length > 5 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </>
    );
  }

  function percentcal(a, b) {
    return String(a) + String(b);
  }

  function ItemsRank({ currentItems }) {
    return (
      <div class="accordion-body">
        <div class="table-responsive mt-3">
          <table class="table">
            <thead>
              <tr>
                {/* <th scope="col">S.No</th> */}
                {/* <th scope="col">ID</th> */}
                <th scope="col">S.No</th>
                <th scope="col">Gold</th>
                {/* <th scope="col">Percentage</th> */}
                <th scope="col">Emrald</th>
                <th scope="col">Diamond</th>
                <th scope="col">BlueDiamond</th>
                <th scope="col">CrownDiamond</th>
                <th scope="col">Ambasitor</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                {/* <th scope="col" className="text-end">
                  Status
                </th> */}
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((hist, i) => {
                  return (
                    <tr>
                      {console.log(hist, "histadfasfasfhist")}
                      <td>{hist?.SSNo}</td>
                      <td>
                        {hist?.GoldValPer > 0 && hist?.GoldValPer != Infinity
                          ? hist?.GoldValPer.toFixed(4)
                          : 0}
                      </td>
                      <td>
                        {" "}
                        {hist?.EmraldValPer > 0 &&
                        hist?.EmraldValPer != Infinity
                          ? hist?.EmraldValPer.toFixed(4)
                          : 0}
                      </td>
                      <td>
                        {hist?.DimondValPer > 0 &&
                        hist?.DimondValPer != Infinity
                          ? hist?.DimondValPer.toFixed(4)
                          : 0}
                      </td>

                      <td>
                        {hist?.BlueDimondValPer > 0 &&
                        hist?.BlueDimondValPer != Infinity
                          ? hist?.BlueDimondValPer.toFixed(4)
                          : 0}
                      </td>

                      <td>
                        {hist?.CrownDimondValPer > 0 &&
                        hist?.CrownDimondValPer != Infinity
                          ? hist?.CrownDimondValPer.toFixed(4)
                          : 0}
                      </td>

                      <td>
                        {hist?.AmbasitorValPer > 0 &&
                        hist?.AmbasitorValPer != Infinity
                          ? hist?.AmbasitorValPer.toFixed(4)
                          : 0}
                      </td>
                      <td>
                        {hist?.Status == 1
                          ? "Inactive"
                          : hist?.Status == 0
                          ? "Active"
                          : "Pending"}{" "}
                      </td>
                      <td>{moment(hist?.createdAt).format("lll")} </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      {walletConnection?.userdetails?.blockStatus == true ? (
        <div className="dashboard g_bg">
          <div className="container">
            <div className="h_flx">
              <h3>Your blocked by admin</h3>
            </div>
          </div>
        </div>
      ) : (
        <div className="dashboard g_bg">
          <div className="container">
            <div className="h_flx">
              <h3>Dashboard</h3>
              <div className="d-flex flex-wrap justify-content-center gap-2">
                {/* <div className="time_box">
                  <img
                    class="img-fluid"
                    src={require("../assets/images/d3.png")}
                    alt="img"
                  />
                  <p>
                    <span>Royalty Reward:</span>{" "}
                    {withFee ? Number(withFee).toFixed(4) : 0}
                  </p>
                </div> */}
                <div className="time_box">
                  <img
                    class="img-fluid"
                    src={require("../assets/images/date.png")}
                    alt="img"
                  />
                  <p>
                    <span> Passive Reward Activation Date :</span>{" "}
                    {moment(walletConnection?.userdetails?.createdAt)
                      .add(2, "days")
                      .format("lll")}
                    {/* 2023-00-00, 00:00 AM */}
                  </p>
                </div>
                <div className="time_box">
                  <img
                    class="img-fluid"
                    src={require("../assets/images/date.png")}
                    alt="img"
                  />
                  <p>
                    <span>Date Of Joining :</span>{" "}
                    {moment(walletConnection?.userdetails?.createdAt).format(
                      "lll"
                    )}
                    {/* 2023-00-00, 00:00 AM */}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-xl-3">
                <div className="box mb-4">
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/user.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>{walletConnection?.userdetails?.userID}</h5>
                      <p>ID No</p>
                    </div>
                  </div>
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d2.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>
                        {walletConnection?.userdetails?.totalIncome
                          ? parseFloat(
                              walletConnection?.userdetails?.totalIncome -
                                walletConnection?.userdetails
                                  ?.withdrawtotalIncome
                            ).toFixed(4)
                          : 0}
                      </h5>
                      <p>Total Available Rewards</p>
                      {walletConnection?.userdetails?.totalIncome -
                        walletConnection?.userdetails?.withdrawtotalIncome >
                        0 && (
                        <button
                          className="join_btn mt-2"
                          disabled={withdrawStop}
                          onClick={() =>
                            handleWithdraw(
                              walletConnection?.userdetails?.totalIncome -
                                walletConnection?.userdetails
                                  ?.withdrawtotalIncome
                            )
                          }
                        >
                          Withdraw
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d2.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>
                        {earndReward ? parseFloat(earndReward).toFixed(4) : 0}
                      </h5>
                      <p>Total Earned Rewards</p>
                    </div>
                  </div>

                  {/* Extra Ewwards */}
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d3.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>
                        {walletConnection?.userdetails?.direcetIncome
                          ? walletConnection?.userdetails?.direcetIncome.toFixed(
                              4
                            )
                          : 0}
                      </h5>
                      <p>Direcet Rewards</p>
                    </div>
                  </div>
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d3.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>
                        {walletConnection?.userdetails?.roiIncome
                          ? formatNumberWithFourDecimalPlaces(
                              walletConnection?.userdetails?.roiIncome
                            )
                          : 0}
                        {/* {formatNumberWithFourDecimalPlaces(4.9999999)} */}
                      </h5>
                      <p>Passive Rewards</p>
                    </div>
                  </div>
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d3.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>
                        {walletConnection?.userdetails?.roiUplinerIncome
                          ? formatNumberWithFourDecimalPlaces(
                              walletConnection?.userdetails?.roiUplinerIncome
                            )
                          : 0}
                        {/* {formatNumberWithFourDecimalPlaces(4.999999)} */}
                      </h5>
                      <p>Passive Royalty Rewards </p>
                    </div>
                  </div>
                  {/* Extra Rewards */}

                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d3.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>
                        {config.defalutAddr == walletConnection.address &&
                        walletConnection?.userdetails?.overAllRoiPercentage -
                          walletConnection?.userdetails?.totalIncome <
                          0
                          ? 0
                          : (
                              walletConnection?.userdetails
                                ?.overAllRoiPercentage -
                              walletConnection?.userdetails?.totalIncome
                            ).toFixed(4)}
                      </h5>
                      <p>Package Remaining Rewards</p>
                    </div>
                  </div>
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d4.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>{currRank ? currRank : "Not Achieved"}</h5>
                      <p>Rank Achieved</p>
                    </div>
                  </div>
                  <div className="inbox">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d10.png")}
                        alt="img"
                      />
                    </div>
                    <div className="w-100">
                      <h5>Brochure </h5>
                      <Link
                        to={require("../assets/pdf/golfinos.pdf")}
                        target="_blank"
                        className="primary_btn dwn_btn px-3 py-2"
                      >
                        Download
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="box mb-4">
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d5.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>
                        {walletConnection?.userdetails?.referrals?.length}
                      </h5>
                      <p>Direct Referrals Count</p>
                    </div>
                  </div>
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d6.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>
                        {userDetails?.referrals?.length > 0
                          ? addToAmount(userDetails?.referrals)
                          : 0}

                        {/* 123 */}
                      </h5>
                      <p> Direct Groups Volume</p>
                    </div>
                  </div>
                  <div className="inbox">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d7.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>{walletConnection?.userdetails?.teamCount}</h5>
                      <p>Total Group Count</p>
                    </div>
                  </div>
                </div>
                <div className="box mb-4">
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d8.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      {console.log(royaltyAchi, "dfsdfsd")}
                      <h5>
                        {royaltyAchi?.gold?.length > 0
                          ? royaltyAchi?.gold?.length
                          : 0}
                      </h5>
                      <p> Total Royalty Achievers </p>
                    </div>
                  </div>
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d9.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>{royaltyVol ? royaltyVol.toFixed(4) : 0}</h5>
                      <p>Total Royalty Fund</p>
                    </div>
                  </div>
                  <div className="inbox mb-2">
                    <div className="img_box">
                      <img
                        class="img-fluid"
                        src={require("../assets/images/d9.png")}
                        alt="img"
                      />
                    </div>
                    <div>
                      <h5>{withFee ? Number(withFee).toFixed(4) : 0}</h5>
                      <p>Royalty Reward</p>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="inbox affiliate">
                    <div className="w-100">
                      <p className="mb-2">Referral Link</p>
                      <div className="link">
                        <div className="half">
                          {config.frontend +
                            "/join-now/" +
                            walletConnection.address}
                          ...
                        </div>
                        <div className="ms-3">
                          <img
                            src={require("../assets/images/copy.png")}
                            onClick={copyToClipboard}
                            alt="copy"
                            className="img-fluid copy"
                          />
                        </div>
                      </div>
                      <ul>
                        <li>
                          <a>
                            <FacebookShareButton
                              url={`${config.frontUrl}join-now/${walletConnection.address}`}
                            >
                              <FacebookIcon size={30} round={true} />
                            </FacebookShareButton>
                          </a>
                        </li>
                        <li>
                          <a>
                            <TwitterShareButton
                              url={`${config.frontUrl}join-now/${walletConnection.address}`}
                            >
                              <TwitterIcon size={30} round={true} />
                            </TwitterShareButton>
                          </a>
                        </li>
                        <li>
                          <a>
                            <TelegramShareButton
                              url={`${config.frontUrl}join-now/${walletConnection.address}`}
                            >
                              <TelegramIcon size={30} round={true} />
                            </TelegramShareButton>
                          </a>
                        </li>
                        <li>
                          <a>
                            <LinkedinShareButton
                              url={`${config.frontUrl}join-now/${walletConnection.address}`}
                            >
                              <LinkedinIcon size={30} round={true} />
                            </LinkedinShareButton>
                          </a>
                        </li>

                        <li>
                          <a>
                            <WhatsappShareButton
                              url={`${config.frontUrl}join-now/${walletConnection.address}`}
                            >
                              <WhatsappIcon size={30} round={true} />
                            </WhatsappShareButton>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-lg-0 mt-4 col-lg-8 col-xl-9">
                <div className="left_side">
                  <div class="accordion" id="accordionExample1">
                    <div class="accordion-item mb-4">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Active Packages
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="row">
                            {planData &&
                              planData.length > 0 &&
                              planData.map((plans, i) => {
                                return (
                                  <div className="col-lg-6 col-xl-4 d-flex">
                                    <div
                                      className={`pack_box ${
                                        // plans?.PlanDetails.length > 0
                                        //   ? "joined"
                                        //   : "disabled"
                                        plans?.PlanDetails.length == 0 &&
                                        plans?.status == 0
                                          ? "disabled"
                                          : "joined"
                                      } w-100`}
                                    >
                                      <div className="img_box">
                                        <img
                                          class="img-fluid"
                                          src={require("../assets/images/d3.png")}
                                          alt="img"
                                        />
                                      </div>
                                      <div>
                                        <h5 className="mb-0">
                                          {plans.PlanAmount} USDT
                                        </h5>
                                        {console.log(
                                          latestPlan?.planAmount,
                                          plans?.PlanAmount,
                                          "adcsac",
                                          latestPlan?.status == 0
                                        )}
                                        {plans?.PlanDetails.length == 0 &&
                                          plans?.status == 0 && (
                                            <button
                                              className="join_btn mt-2"
                                              onClick={() =>
                                                handleRejoin(
                                                  i,
                                                  plans?.PlanAmount
                                                )
                                              }
                                            >
                                              Activate
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item mb-4">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          {/* Package Summary */}
                          Direct Income History
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <PaginatedItemsItemsDiret itemsPerPage={6} />
                      </div>
                    </div>

                    {/* Newwwwwwww */}
                    <div class="accordion-item mb-4">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          Genealogy
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body overflow-x-scroll">
                          <div class="tree">
                            <ul>
                              <li className="current_user">
                                <a
                                  style={{
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    data-tooltip-id="my-tooltip-1"
                                    class="img-fluid"
                                    style={{ width: "41px" }}
                                    src={require("../assets/images/user_ylw.png")}
                                    alt="img"
                                  />
                                  <ReactTooltip
                                    id="my-tooltip-1"
                                    place="top"
                                    content={
                                      <>
                                        Group volume : {treeData?.totalAmount}
                                      </>
                                    }
                                  />
                                  <span>{treeData?.userid}</span>
                                </a>
                                <ul>
                                  {treeData &&
                                    treeData?.referrals &&
                                    treeData?.referrals.length > 0 &&
                                    treeData?.referrals.map((ele) => {
                                      return (
                                        <li>
                                          <a
                                            style={{
                                              cursor: "pointer"
                                            }}
                                          >
                                            <img
                                              data-tooltip-id={`my-tooltip-${ele?.userid}`}
                                              class="img-fluid usr_rose"
                                              src={require("../assets/images/user_rose.png")}
                                              alt="img"
                                              onClick={() => {
                                                handleTreeChange(ele?.userid);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele?.userid
                                                ]);
                                              }}
                                            />
                                            <ReactTooltip
                                              id={`my-tooltip-${ele?.userid}`}
                                              place="top"
                                              content={
                                                <>
                                                  Group volume :{" "}
                                                  {ele.totalAmount}
                                                </>
                                                // <div>
                                                //   <p className="mb-0">
                                                //     {ele?.totalAmount}
                                                //   </p>
                                                // </div>
                                              }
                                            />
                                            <span>{ele.userid}</span>
                                          </a>
                                          <ul>
                                            {ele &&
                                              ele.referrals &&
                                              ele.referrals.length > 0 &&
                                              ele?.referrals.map((elem) => {
                                                return (
                                                  <li>
                                                    <a
                                                      style={{
                                                        cursor: "pointer"
                                                      }}
                                                    >
                                                      <img
                                                        data-tooltip-id={`my-tooltip-${elem?.userid}`}
                                                        class="img-fluid usr_green"
                                                        src={require("../assets/images/user_green.png")}
                                                        alt="img"
                                                        // onClick={() =>
                                                        //   handleTreeChange(
                                                        //     elem?.userid
                                                        //   )
                                                        // }
                                                        onClick={() => {
                                                          handleTreeChange(
                                                            elem?.userid
                                                          );
                                                          setHistoryTree(
                                                            (prev) => [
                                                              ...prev,
                                                              elem?.userid
                                                            ]
                                                          );
                                                        }}
                                                      />
                                                      <ReactTooltip
                                                        id={`my-tooltip-${elem?.userid}`}
                                                        place="top"
                                                        content={
                                                          <>
                                                            Group Volume :{" "}
                                                            {elem.totalAmount}
                                                          </>
                                                        }
                                                      />
                                                      <span>{elem.userid}</span>
                                                    </a>
                                                    {/* <ul>
                                            <li>
                                              <a href="#">
                                                <img
                                                  class="img-fluid usr_vio"
                                                  src={require("../assets/images/user_violet.png")}
                                                  alt="img"
                                                />
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <img
                                                  class="img-fluid usr_vio"
                                                  src={require("../assets/images/user_violet.png")}
                                                  alt="img"
                                                />
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <img
                                                  class="img-fluid usr_vio"
                                                  src={require("../assets/images/user_violet.png")}
                                                  alt="img"
                                                />
                                              </a>
                                            </li>
                                          </ul> */}
                                                  </li>
                                                );
                                              })}

                                            {/* Rmeoved Two */}

                                            {/* Removed Two */}
                                          </ul>
                                        </li>
                                      );
                                    })}

                                  {/* Removed One */}

                                  {/* Remove One */}
                                </ul>
                              </li>
                            </ul>
                          </div>
                          <button
                            onClick={onHistoryChange}
                            className="join_btn mt-2 float-right"
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Newwww */}
                    <div class="accordion-item mb-4">
                      <h2 class="accordion-header" id="headingfour">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsefour"
                          aria-expanded="true"
                          aria-controls="collapsefour"
                        >
                          Withdraw History
                        </button>
                      </h2>
                      <div
                        id="collapsefour"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingfour"
                        data-bs-parent="#accordionExample"
                      >
                        <PaginatedItems itemsPerPage={10} />
                      </div>
                    </div>
                    <div class="accordion-item mb-4">
                      <h2 class="accordion-header" id="headingfive">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsefive"
                          aria-expanded="true"
                          aria-controls="collapsefive"
                        >
                          Package History
                        </button>
                      </h2>
                      <div
                        id="collapsefive"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingfive"
                        data-bs-parent="#accordionExample"
                      >
                        <PaginatedItemsItemsPacHis itemsPerPage={10} />
                      </div>
                    </div>

                    <div class="accordion-item mb-4">
                      <h2 class="accordion-header" id="headingsix">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsesix"
                          aria-expanded="true"
                          aria-controls="collapsesix"
                        >
                          Passive Rewards History
                        </button>
                      </h2>
                      <div
                        id="collapsesix"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingsix"
                        data-bs-parent="#accordionExample"
                      >
                        <PaginatedItemsROI itemsPerPage={6} />
                      </div>
                    </div>
                    <div class="accordion-item mb-4">
                      <h2 class="accordion-header" id="headingseven">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseseven"
                          aria-expanded="true"
                          aria-controls="collapseseven"
                        >
                          Passive Royalty History
                        </button>
                      </h2>
                      <div
                        id="collapseseven"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingseven"
                        data-bs-parent="#accordionExample"
                      >
                        <PaginatedItemsROIUpli itemsPerPage={6} />
                      </div>
                    </div>

                    <div class="accordion-item mb-4">
                      <h2 class="accordion-header" id="headingeight">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseeight"
                          aria-expanded="true"
                          aria-controls="collapseeight"
                        >
                          Rank Income History
                        </button>
                      </h2>
                      <div
                        id="collapseeight"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingeight"
                        data-bs-parent="#accordionExample"
                      >
                        <PaginatedItemsRank itemsPerPage={6} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loader && (
        <div className="loadBefore">
          <ClockLoader
            color={"#FFF7A1"}
            loading={loader}
            cssOverride={override}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <Footer />
    </div>
  );
}
