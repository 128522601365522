let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};
let Envname = "demo";

if (Envname === "prodction") {
  console.log(
    "Set Prodction Config 0xa79733cbDe796cf110f9d52B24F26dAdA06f4707"
  );
  key = {
    baseUrl: `https://prodapi.golfinos.biz`,
    frontend: "https://www.golfinos.biz",
    NetworkId: 56,
    frontUrl: "https://www.golfinos.biz/",
    GolfiContract: "0xa79733cbDe796cf110f9d52B24F26dAdA06f4707",
    USDTContract: "0x55d398326f99059ff775485246999027b3197955",
    defalutAddr: "0x4c5c6b17843808b1442F8D41aC391396B68a32DD",
    rpcUrl: "https://bsc-dataseed.binance.org",
    bscscan: "https://bscscan.com/",
    decimalVal: 1e18
  };
} else if (Envname === "demo") {
  console.log("Set Demo Config");
  key = {
    baseUrl: `https://golfinosapi.wealwin.com`,
    frontend: "https://golfinos-mlm-frontend.pages.dev",
    NetworkId: 97,
    frontUrl: "https://golfinos-mlm-frontend.pages.dev/",
    GolfiContract: "0x4437AD3082c83F245256A3b1777242D307300551",
    USDTContract: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    defalutAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    rpcUrl: "https://bsc-testnet.publicnode.com",
    bscscan: "https://testnet.bscscan.com/",
    decimalVal: 1e18
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    frontend: "http://localhost:3000",
    NetworkId: 97,
    frontUrl: "http://localhost:3000/",
    GolfiContract: "0x3B28E74214c59752c8B064EA719E7428Ee8e94eE",
    USDTContract: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    defalutAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    rpcUrl: "https://bsc-testnet.publicnode.com",
    bscscan: "https://testnet.bscscan.com/",
    decimalVal: 1e18
  };
}

export default key;
