import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import config from "../lib/config.js";
import { useSelector } from "react-redux";
import { getTotalUP, get_faq_list } from "../Actions/apiAction.js";
import moment from "moment";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [activeTab, setActiveTab] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPayout, setTotalPayout] = useState(0);
  const [latestData, setLatestData] = useState([]);
  const [faqlist, setfaqlist] = useState([]);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    getTotalUserPayout();
    getFAQ();
  }, []);

  const getFAQ = async () => {
    var { result, status } = await get_faq_list();
    console.log(result, status, "result, status");
    if (status && result) {
      setfaqlist(result);
    }
  };

  const getTotalUserPayout = async () => {
    try {
      const data = await getTotalUP();
      console.log(data.data, "dataUP");
      setTotalUsers(data?.data?.Udata.length);
      setTotalPayout(data?.data?.Pdata[0]?.totalPayout);
      setLatestData(data?.data?.RecentHis);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDirection = (navigate) => {
    if (navigate == "dashboard") {
      window.location.href = "/dashboard";
    } else if (navigate == "joinnow") {
      window.location.href = "/join-now";
    }
  };

  return (
    <div>
      {/* <ScrollToTopOnMount /> */}
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 m-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h1>
                Smart Contract
                <span> Trading Platform</span>
              </h1>
              <div className="row">
                <div className="col-lg-8">
                  <p className="para">
                    Earn maximum by investing minimum. Discover the newest
                    treasures with <span>Golfinos</span>
                  </p>
                </div>
              </div>
              {!walletConnection.address && (
                <button
                  className="primary_btn mb-2"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  <span class="text1"> Connect Wallet</span>
                  <span class="icon">
                    <img
                      src={require("../assets/images/wallet.png")}
                      width={42}
                      height={43}
                      className="img-fluid"
                    />{" "}
                  </span>
                  <span class="text2">Connect Wallet</span>
                </button>
              )}
              {walletConnection.address && (
                <>
                  {" "}
                  {walletConnection?.userdetails ? (
                    <button
                      className="primary_btn mb-2"
                      onClick={() => handleDirection("dashboard")}
                    >
                      <span class="text1"> Dashboard</span>
                      <span class="icon">
                        <img
                          src={require("../assets/images/wallet.png")}
                          width={42}
                          height={43}
                          className="img-fluid"
                        />{" "}
                      </span>
                      <span class="text2">Dashboard</span>
                    </button>
                  ) : (
                    <button
                      className="primary_btn mb-2"
                      onClick={() => handleDirection("joinnow")}
                    >
                      <span class="text1"> Join Now</span>
                      <span class="icon">
                        <img
                          src={require("../assets/images/wallet.png")}
                          width={42}
                          height={43}
                          className="img-fluid"
                        />{" "}
                      </span>
                      <span class="text2">Join Now </span>
                    </button>
                  )}
                </>
              )}
            </div>
            <div
              className="col-lg-7"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="img_box">
                <div class="coin_wrapper">
                  <div class="coin">
                    <div class="side heads">
                      <img
                        class="imageCoin"
                        src={require("../assets/images/ban_pic.png")}
                        alt="king_coin_1"
                      />
                    </div>
                    <div class="side tails">
                      <img
                        class="imageCoin"
                        src={require("../assets/images/ban_pic.png")}
                        alt="King_coin_2"
                      />
                    </div>
                  </div>
                </div>
                <img
                  src={require("../assets/images/banimg.png")}
                  className="img-fluid"
                />{" "}
              </div>
              {/* <div id="main">
              <div class="coin">
                <div class="tails"></div>
                <div class="heads"></div>
              </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="total">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-10 col-xxl-10 mx-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div className="tot_box">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/tb1.png")}
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div>
                          <p>Total Payouts</p>
                          <h3>{totalPayout ? totalPayout.toFixed(2) : 0} </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="box right_box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/tb2.png")}
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div>
                          <p>Total Participants</p>
                          <h3>{totalUsers ? totalUsers : 0}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="g_bg">
          <section className="features">
            <div className="container">
              <div
                className="head pb-5"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                {/* <h2 className="h2tag">Our Features</h2> */}
              </div>
              <div className="row">
                <div
                  className="col-lg-4 d-flex"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <div className="box">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/f1.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h6>WHO WE ARE</h6>
                    <p className="para">
                      GolFinos Opening up a Trading platform – explains why we
                      exist. We’re here to use our unique expertise,
                      capabilities, breadth and perspectives to open up new
                      kinds of Gold & Forex trading for our customers.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 d-flex"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <div className="box">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/f2.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h6>WHAT IS FOREX TRADING </h6>
                    <p className="para">
                      Forex — or FX — refers to the foreign exchange market, and
                      forex trading is the process of buying and selling
                      currencies from around the globe. The forex market is the
                      largest financial market in the world, but one in which
                      many individual investors have never dabbled, in part
                      because it’s highly speculative and complex.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 d-flex"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  <div className="box">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/f3.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h6>WHY CHOOSE GOLFINOS? </h6>
                    <p className="para">
                      Anyone can begin their journey in the world of trading
                      with GolFinos. As a platform, GolFinos provides
                      world-class professional hedge fund managers to generate
                      your asset in secure way.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="rewards">
            <div class="container">
              <div className="row">
                <div className="col-lg-12 mx-auto">
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    <h2 className="h2tag">Earn to Rewards!</h2>
                    <div className="tab-container">
                      <div
                        className={`tab ${activeTab === 1 ? "active" : ""}`}
                        onClick={() => handleTabClick(1)}
                      >
                        Gold
                      </div>
                      <div
                        className={`tab ${activeTab === 2 ? "active" : ""}`}
                        onClick={() => handleTabClick(2)}
                      >
                        Emerald
                      </div>
                      <div
                        className={`tab ${activeTab === 3 ? "active" : ""}`}
                        onClick={() => handleTabClick(3)}
                      >
                        Diamond
                      </div>

                      <div
                        className={`tab ${activeTab === 4 ? "active" : ""}`}
                        onClick={() => handleTabClick(4)}
                      >
                        Double Gold
                      </div>
                      <div
                        className={`tab ${activeTab === 5 ? "active" : ""}`}
                        onClick={() => handleTabClick(5)}
                      >
                        Double Emerald
                      </div>
                      <div
                        className={`tab ${activeTab === 6 ? "active" : ""}`}
                        onClick={() => handleTabClick(6)}
                      >
                        Double Diamond
                      </div>
                    </div>
                    <div className="tab-content">
                      <div
                        className={`${activeTab === 1 ? "show" : "hide"} tabs `}
                      >
                        <ul>
                          <li>
                            <span>30,000 USDT</span> (total team) in 3 different
                            legs with 10000 usd one leg 2% Royalty on Total
                            Transaction Fees divided among all the eligible
                            Gold.{" "}
                          </li>
                          <li>
                            once you achieve reward Gold maintain the personal
                            staking 5000 USDT
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${activeTab === 2 ? "show" : "hide"} tabs `}
                      >
                        <ul>
                          <li>
                            <span>90,000 USDT</span>{" "}
                             (total team) in 3 different legs with 30000 usd one leg 2% Royalty on Total
                            Transaction Fees divided among all the eligible EMERALD
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${activeTab === 3 ? "show" : "hide"} tabs `}
                      >
                        <ul>
                          <li>
                            <span>1,50,000 USDT</span>
                             (total team) in 3 different legs with 50000 usd one leg 2% Royalty on
                            Total Transaction Fees divided among all the eligible Diamond
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${activeTab === 4 ? "show" : "hide"} tabs `}
                      >
                        <ul>
                          <li>
                            Your Total Team in 2 Gold different legs get  2% Royalty on Total Transaction Fees
                            divided among all the eligible Double GOLD
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${activeTab === 5 ? "show" : "hide"} tabs `}
                      >
                        <ul>
                          <li>
                            Your Total Team in 2 EMERALD different legs get  1% Royalty on Total Transaction Fees
                            divided among all the eligible Double EMERALD{" "}
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${activeTab === 6 ? "show" : "hide"} tabs `}
                      >
                        <ul>
                          <li>
                            {" "}
                            Your Total Team in 2 Diamond different legs get  1% Royalty on Total Transaction Fees
                            divided among all the eligible Double Diamond{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="platform_activity ">
            <div className="container">
              <div className="head" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="h2tag mb-5 text-center w-100 d-inline-block">
                  <span> Platform Recent Activity</span>
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <div
                    className="activity_box"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <Scrollbars style={{ width: "100%", height: 310 }}>
                      <div className="activity_scroll">
                        {latestData &&
                          latestData.length > 0 &&
                          latestData.map((History) => {
                            return (
                              <div
                                className="activity_box_flx"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                data-aos-delay="100"
                              >
                                <div>
                                  <div className="flxbox">
                                    <div>
                                      <img
                                        src={require("../assets/images/logo_icon.png")}
                                        className="img-fluid"
                                        alt="img"
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={require("../assets/images/user_prf.png")}
                                        className="img-fluid"
                                        alt="img"
                                      />
                                    </div>
                                    <div>
                                      <p className="violet">
                                        {History.planAmount == 50
                                          ? "New User Joined"
                                          : "User Rejoined"}
                                      </p>
                                    </div>
                                    <div>
                                      <div>ID {History?.userID}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="rightbtn">
                                  <p>
                                    <span>
                                      {" "}
                                      <a href="#" target="_blank">
                                        <img
                                          src={require("../assets/images/arrow.png")}
                                          className="img-fluid me-2"
                                          alt="img"
                                        />
                                      </a>
                                    </span>
                                    <span>
                                      {" "}
                                      {moment(History?.createdAt)
                                        .startOf("hour")
                                        .fromNow()}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })}

                        {/* <div
                          className="activity_box_flx"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="200"
                        >
                          <div>
                            <div className="flxbox">
                              <div>
                                <img
                                  src={require("../assets/images/logo_icon.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              </div>
                              <div>
                                <img
                                  src={require("../assets/images/user_prf.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              </div>
                              <div>
                                <div>ID 281519</div>
                              </div>
                              <div>
                                <p className="green">
                                  +40 BSC in <span>x4</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="rightbtn">
                            <p>
                              <span>
                                {" "}
                                <a href="#" target="_blank">
                                  <img
                                    src={require("../assets/images/arrow.png")}
                                    className="img-fluid me-2"
                                    alt="img"
                                  />
                                </a>
                              </span>
                              <span> 1 minute</span>
                            </p>
                          </div>
                        </div>

                        <div
                          className="activity_box_flx"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="300"
                        >
                          <div>
                            <div className="flxbox">
                              <div>
                                <img
                                  src={require("../assets/images/logo_icon.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              </div>
                              <div>
                                <img
                                  src={require("../assets/images/user_prf.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              </div>
                              <div>
                                <div>ID 281519</div>
                              </div>
                              <div>
                                <p className="green">
                                  +40 BSC in <span>x4</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="rightbtn">
                            <p>
                              <span>
                                {" "}
                                <a href="#" target="_blank">
                                  <img
                                    src={require("../assets/images/arrow.png")}
                                    className="img-fluid me-2"
                                    alt="img"
                                  />
                                </a>
                              </span>
                              <span> 1 minute</span>
                            </p>
                          </div>
                        </div>

                        <div
                          className="activity_box_flx"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="400"
                        >
                          <div>
                            <div className="flxbox">
                              {" "}
                              <div>
                                <img
                                  src={require("../assets/images/logo_icon.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              </div>
                              <div>
                                <img
                                  src={require("../assets/images/user_prf.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              </div>
                              <div>
                                <p className="violet">New user joined</p>
                              </div>
                              <div>
                                <div>ID 1210781</div>
                              </div>
                            </div>
                          </div>
                          <div className="rightbtn">
                            <p>
                              <span>
                                {" "}
                                <a href="#" target="_blank">
                                  <img
                                    src={require("../assets/images/arrow.png")}
                                    className="img-fluid me-2"
                                    alt="img"
                                  />
                                </a>
                              </span>
                              <span> 1 minute</span>
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* FAQ static */}

          <section class="faq">
            <div class="container">
              <div
                class="head aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 class="h2tag text-center mx-auto mb-4">
                  {/* Frequently Asked Questions */}
                  Important Note
                </h2>
              </div>
              <div class="accordion" id="accordionExample">
                <ul className="tetsLi">
                  <li>
                    Once you have connected your wallet with GolFinos smart
                    contract by subscribing any one package your ID will be
                    created and you can able to transact with GoFinos from your
                    personal dashboard provided by GolFinos, After activating
                    any one package in GolFinos again you can able to activate
                    different packages simultaneously.
                  </li>
                  <li>
                    Subscribing any packages from GolFinos passive income will
                    be generated after 48 hours,
                  </li>
                  <li>Passive rewards will be paid only 5 days week,</li>
                  <li>
                    Any withdrawal 10% will be deducted for royalty income
                  </li>
                  <li>
                    All royalty income will be paid 2nd week of Friday and 4th
                    week of every Friday.
                  </li>
                  <li>
                    All the income paid by GolFinos is based on trading profit
                    generated by GolFinos fund managers
                  </li>
                  <li>
                    All the income assured by GolFinos is based on market risk.
                  </li>
                </ul>
                {/* <div
                  class="accordion-item aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      1. What is Golfinos?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore elit, sed do
                        eiusmod tempor incididunt ut labore labore elit, sed do
                        eiusmod tempor incididunt ut labore{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      2.Are staking rewards the same as interest payments?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore elit, sed do
                        eiusmod tempor incididunt ut labore labore elit, sed do
                        eiusmod tempor incididunt ut labore{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="600"
                >
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      3.How often are rewards paid out?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore elit, sed do
                        eiusmod tempor incididunt ut labore labore elit, sed do
                        eiusmod tempor incididunt ut labore{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="900"
                >
                  <h2 class="accordion-header" id="headingfour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      4.Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                    </button>
                  </h2>
                  <div
                    id="collapsefour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingfour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore elit, sed do
                        eiusmod tempor incididunt ut labore labore elit, sed do
                        eiusmod tempor incididunt ut labore{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          {/* FAQ dynamic */}

          {/* <section class="faq">
            <div class="container">
              <div
                class="head aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 class="h2tag text-center mx-auto mb-4">
                  Frequently Asked Questions
                </h2>
              </div>
              <div class="accordion" id="accordionExample">
                {faqlist &&
                  faqlist?.length > 0 &&
                  faqlist?.map((items, index) => (
                    <>
                      <div
                        class="accordion-item aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <h2 class="accordion-header" id={`heading${index + 1}`}>
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index + 1}`}
                            aria-expanded="true"
                            aria-controls={`collapse${index + 1}`}
                          >
                            {index + 1}. {items?.Question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index + 1}`}
                          class="accordion-collapse collapse show"
                          aria-labelledby={`heading${index + 1}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p class="para">{items?.Answer}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </section> */}
        </div>

        <Footer />
      </div>
    </div>
  );
}
