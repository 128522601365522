import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { toastAlert } from "../helper/toastAlert.js";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import copy from "copy-to-clipboard";
import Web3 from "web3";
import config from "../lib/config.js";
import USDTABI from "../ABI/USDTABI.json";
import GoldinosABI from "../ABI/GoldinosABI.json";
import { getSettingsData } from "../Actions/apiAction.js";
// import { Tooltip as ReactTooltip } from "react-tooltip";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Joinnow(props) {
  const { id } = useParams();
  const walletConnection = useSelector((state) => state.walletConnection);
  const navigate = useNavigate();
  const [refferalID, setRefferalID] = useState(id);
  const [validation, setValidaion] = useState({ refVal: "" });
  const [loader, setLoader] = useState(false);
  const [regisStop, setRegisStop] = useState(false);

  useEffect(() => {
    userStatusCheck();
    getSetting();
  }, [walletConnection.address]);

  const getSetting = async () => {
    try {
      const data = await getSettingsData();
      console.log(data, "Settings");

      setRegisStop(data?.result?.registrationStatus);
    } catch (rtt) {
      console.log(rtt);
    }
  };

  const userStatusCheck = async () => {
    try {
      if (walletConnection.address) {
        var web3 = new Web3(walletConnection.web3);
        var ContractGol = new web3.eth.Contract(
          GoldinosABI,
          config.GolfiContract
        );
        let userdetails = await ContractGol.methods
          .users(walletConnection.address)
          .call();
        if (userdetails.isExist) {
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1000);
        }
      }
    } catch (err) {
      console.log(err, "userStatusCheck");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name == "RefferalID") {
      setRefferalID(e.target.value);
    }
  };

  const handleJoinSubmit = async () => {
    try {
      setLoader(true);

      if (walletConnection.address) {
        console.log(refferalID, "refferalID");
        var web3 = new Web3(walletConnection.web3);
        if (refferalID == "" || refferalID == undefined) {
          setValidaion({ refVal: "* Referral ID is required" });
          setLoader(false);
          return false;
        }
        if (!web3.utils.isAddress(refferalID)) {
          setValidaion({ refVal: "* Referral ID is not valid" });
          setLoader(false);
          return false;
        }
        setValidaion({ refVal: "" });

        let bnbBal = await web3.eth.getBalance(walletConnection.address);
        bnbBal = bnbBal / config.decimalVal;
        console.log(bnbBal, "bnbBal");

        const ContractToken = new web3.eth.Contract(
          USDTABI,
          config.USDTContract
        );
        let tokenBal = await ContractToken.methods
          .balanceOf(walletConnection.address)
          .call();
        tokenBal = tokenBal / 1e18;
        console.log(tokenBal, "tokenBal");

        const ContractGolfi = new web3.eth.Contract(
          GoldinosABI,
          config.GolfiContract
        );
        console.log(ContractGolfi, "ContractGolfi");

        const isRegistr = await ContractGolfi.methods.isRegister().call();
        console.log(isRegistr, "isRegistr");
        if (!isRegistr) {
          setValidaion({ refVal: "* Registration blocked by admin" });
          setLoader(false);
          return false;
        }
        setValidaion({ refVal: "" });

        let isBlock = await ContractGolfi.methods.isBlocked(refferalID).call();
        console.log(isBlock, "isBlockjonRef");

        if (isBlock) {
          setValidaion({ refVal: "* Your referral blocked by admin" });
          setLoader(false);
          return false;
        }
        setValidaion({ refVal: "" });

        const isUserExits = await ContractGolfi.methods
          .users(refferalID)
          .call();
        console.log(isUserExits, "isUserExits");
        if (!isUserExits.isExist) {
          setValidaion({ refVal: "* Refferal not exits" });
          setLoader(false);
          return false;
        }
        setValidaion({ refVal: "" });

        const isUserExits1 = await ContractGolfi.methods
          .users(walletConnection.address)
          .call();
        console.log(isUserExits1, "isUserExits1");
        if (isUserExits1.isExist) {
          setValidaion({ refVal: "* User already exits" });
          setLoader(false);
          return false;
        }
        setValidaion({ refVal: "" });

        let firstPlan = await ContractGolfi.methods.poolInfo("0").call();
        let planAmount = Number(firstPlan.poolAmount) / config.decimalVal;
        console.log(planAmount, "firstPlan", firstPlan);
        if (Number(planAmount) > Number(tokenBal)) {
          setValidaion({ refVal: "* Your USDT balance is low" });
          setLoader(false);
          return false;
        }
        setValidaion({ refVal: "" });

        let estimateGas = await ContractToken.methods
          .approve(config.GolfiContract, (planAmount * 1e18).toString())
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));
        let gasPricebusd = await web3.eth.getGasPrice();
        console.log(estimateGas * gasPricebusd, "estimateGas");
        let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
        console.log(estimateGasFee, bnbBal, estimateGasFee > bnbBal, "edwfced");
        if (estimateGasFee > bnbBal) {
          setValidaion({ refVal: "* Your BNB balance is low" });
          setLoader(false);
          return false;
        }
        setValidaion({ refVal: "" });

        await ContractToken.methods
          .approve(config.GolfiContract, (planAmount * 1e18).toString())
          .send({ from: walletConnection.address })
          .then(async (result) => {
            if (result && result.status && result.transactionHash) {
              await ContractGolfi.methods
                .joinPlan("0", refferalID)
                .send({ from: walletConnection.address })
                .then((res) => {
                  if (res && res.status && res.transactionHash) {
                    toastAlert("success", "Joined Successfully");
                    // setLoader(false);
                    setTimeout(() => {
                      window.location.href = "/dashboard";
                    }, 3000);
                  }
                })
                .catch((err) => {
                  console.log(err, "ErrorJoinPl");
                  setLoader(false);
                });
            }
          })
          .catch((err) => {
            console.log(err, "ErrorAppro");
            setLoader(false);
          });
      } else {
        toastAlert("error", "Please connect wallet");
        setLoader(false);
      }
    } catch (err) {
      console.log(err, "SubmitErr");
    }
  };

  async function copyToClipboard() {
    if (walletConnection.address) {
      copy(config.defalutAddr);
      toastAlert("success", "Copied Successfully");
    } else {
      toastAlert("error", "Please connect your wallet");
    }
  }

  return (
    <div>
      <Navbar />
      <div className="joinnow">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-5 mx-auto">
              <div className="box">
                <h2 className="h2tag mb-4 text-center w-100">Joinnow</h2>
                <label className="mb-2">Referral ID</label>
                <div class="input-group mb-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Referral ID"
                    name="RefferalID"
                    value={refferalID}
                    disabled={id ? true : false}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onChange={handleChange}
                  />
                </div>
                <span
                  className="mt-4"
                  style={{ fontSize: "13px", marginTop: "10px" }}
                >
                  Default ID : {config.defalutAddr}{" "}
                  {id ? (
                    ""
                  ) : (
                    <img
                      src={require("../assets/images/copy.png")}
                      onClick={copyToClipboard}
                      style={{
                        cursor: "pointer",
                        marginLeft: "2px",
                        marginTop: "-4px",
                        width: "14px"
                      }}
                      alt="copy"
                      className="img-fluid copy"
                    />
                  )}
                </span>
                {console.log(validation, "validation")}

                <p className="mb-4 text-danger mt-2">
                  {validation.refVal ? validation.refVal : ""}
                </p>

                <div className="text-center">
                  {regisStop ? (
                    <span style={{ color: "red" }}>
                      * Admin currently stop join now *
                    </span>
                  ) : (
                    <button className="primary_btn" onClick={handleJoinSubmit}>
                      <span class="text1"> Submit</span>
                      <span class="icon">
                        <img
                          src={require("../assets/images/btn_arw.png")}
                          className="img-fluid"
                        />{" "}
                      </span>
                      <span class="text2">Submit </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loadBefore">
          <ClockLoader
            color={"#FFF7A1"}
            loading={loader}
            cssOverride={override}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <Footer />
    </div>
  );
}
